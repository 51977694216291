<template>
    <main class="main cardProduct_page">
        <div class="section section--breadcrumbs">
            <div class="container-l">
                <div class="">
                    <breadcrumbs :productName="product.name" />
                </div>
            </div>
        </div>
        <div class="section section--no-pt pb-0 mb-4">
            <div class="container-l" v-if="product">
                <div class="inner">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>{{ product.name }}</h2>
                        </div>
                    </div>
                </div>
                <div class="row good" data-good>
                    <div class="col">
                        <div class="good-gallery" v-if="catalogImgs && catalogImgs.length != 0">
                            <div class="swiper-container slider slider--good-gallery">
                                <div class="swiper-wrapper">
                                    <div 
                                        v-for="(image, i) in catalogImgs.slice(currentImgIndex, currentImgIndex+1)" 
                                        :key="i + 'A'" 
                                        class="swiper-slide"
                                    >
                                        <img
                                            v-if="!currentColor.link"
                                            :src="yandexCloud + image" 
                                            alt=""
                                            :style="(colorCardProduct ? 'background-color:' + colorCardProduct : 'background-color:' + defaultImgColor)"
                                        >
                                        <img
                                            v-else
                                            :src="yandexCloud + image" 
                                            alt=""
                                            :style="{ backgroundImage: 'url(' + yandexCloud + currentColor.link + ')' }"
                                        >
                                    </div>
                                    
                                </div>
                            </div>
                            <div 
                                v-if="catalogImgs && catalogImgs.length > 1"
                                class="swiper-button-prev" 
                                @click="prevImg"   
                            />
                            <div 
                                v-if="catalogImgs && catalogImgs.length > 1"
                                class="swiper-button-next" 
                                @click="nextImg"  
                            />
                        </div>
                        <div class="good-gallery" v-if="!catalogImgs">
                            <div class="swiper-container slider slider--good-gallery">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="@/assets/img/default_img_nomenclature.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="list list--flex layout-good-gallery-thumbs" v-if="catalogImgs">
                            <li class="list-item" v-for="(image, i) in catalogImgs" :key="i + 'B'" @click="openModal(i)">
                                <a class="thumb" data-modal data-index="1">
                                    <img :src="yandexCloud + image" alt="" :style="'background-color:' + defaultImgColor">  
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <h3>Параметры</h3>
                        
                        <div 
                            class="good-group" 
                            :class="{'good-group--row': !block.color}" 
                            v-for="(block, i) in blocks" 
                            :key="i" 
                            v-show="block.array.length != 0"
                        >
                            <single-parameter 
                                v-for="parameter in block.array" 
                                :key="parameter.sort" 
                                :parameter="parameter" 
                                :product="(!product.is_sub_sign ? product : subElementsProuducts[0])" 
                                :typeCard="(!product.is_sub_sign ? 'product' : 'category')"
                                :subElementsProuducts="subElementsProuducts"
                                :changeParameter="changeParameter"
                                :cardForm="form"
                                :form="form"
                                :isSubSign="product.is_sub_sign"
                                :isShowList="isShowList"
                                :parentProductId="productId"
                                :localArrayColors="localArrayColors"
                                @getSubElements="getSubElements"
                                @setPositionColor="setPositionColor"
                                @updateLocalForm="updateLocalForm"
                            />
                        </div>
                    
                        <div class="good-group">
                            <div class="good-col">
                                <div class="label">Цена</div>
                                <div class="value">
                                    <span 
                                        v-if="cardProductIsReady"
                                        class="value-text"
                                    >
                                        от 
                                        <span>
                                            {{ getFixedPrice(editNewPosition.price) }} 
                                        </span>
                                    </span>
                                    <span 
                                        v-else
                                        class="value-text"
                                    >
                                        от 
                                        <span>
                                            0
                                        </span>
                                    </span>
                                    <span 
                                        v-if="regionCode"
                                        class="value-measure"
                                    >
                                        {{ regionCurrency[regionCode].currency }}
                                        <br> 
                                        / {{ newPosition.base_unit_of_measure || product.base_unit_of_measure }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="good-info"> 
                            <div class="good-info-body">
                                <div class="good-info-col">
                                    <div class="label">Количество (шт.)</div>
                                    <div class="counter">
                                        <button 
                                            class="counter-minus" 
                                            @click="changeAmount('minus')"
                                        >
                                            -
                                        </button>
                                        <input 
                                            type="text"  
                                            max="9999"
                                            :data-min="amount" 
                                            data-mask-number data-good-count
                                            v-model="amount"
                                            :class="getClassCounterWithOfAmountPos"
                                            @keyup="changeAmount()"
                                        >
                                        <button class="counter-plus" @click="changeAmount('plus')">+</button>
                                    </div>
                                </div>
                                <div class="good-info-col">
                                    <div class="label">Количество {{ newPosition.base_unit_of_measure || product.base_unit_of_measure }}</div>
                                    <div class="value">
                                        <span class="value-text">
                                            <span>
                                                <span 
                                                    :data-good-value="(newPosition.count_base_unit_of_measure != null ? newPosition.count_base_unit_of_measure : 0)"
                                                >
                                                    {{ (newPosition.count_base_unit_of_measure != null ? newPosition.count_base_unit_of_measure : 0) }}
                                                </span> 
                                                {{ newPosition.base_unit_of_measure || product.base_unit_of_measure }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="good-info-col">
                                    <div class="label">Стоимость</div>
                                    <div class="value">
                                        <span 
                                            v-if="cardProductIsReady"
                                            class="value-text"
                                        >
                                            <span :data-good-value="newPosition.sell">
                                                {{ (newPosition.sell ? getFixedPrice(newPosition.sell) : getFixedPrice(editNewPosition.price))}}
                                            </span>
                                        </span>
                                        <span 
                                            v-else
                                            class="value-text"
                                        >
                                            <span :data-good-value="0">
                                                0
                                            </span>
                                        </span>
                                    <span 
                                        v-if="regionCode"
                                        class="value-measure"
                                    >
                                        {{ regionCurrency[regionCode].currency }}
                                    </span>
                                </div>
                                </div>
                            </div>
                            <div class="good-info-footer">
                                <ul class="list list--flex layout-buttons">
                                    <li class="list-item">
                                        <button 
                                            class="button button--primary" 
                                            :disabled="(!cardProductIsReady ? true : false)"
                                            :class="(!cardProductIsReady ? 'disabled' : '')"
                                            @click="addInBasket"
                                        >
                                            В корзину
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section--no-pt pb-0 mb-2">
            <div class="container-l">
                <div class="row cardProduct_row_info">
                    <div 
                        v-if="commentProductIfGroup"
                        class="col description_col" 
                    >
                        <h3>Описание</h3>
                        <div class="description">
                            <p>{{ commentProductIfGroup }}</p>
                        </div>
                    </div>
                    <div v-if="charakteristic.length != 0" class="col props_col">
                        <h3>Характеристики</h3>
                        <div class="table table--good-properties">
                            <div class="table-body">
                                <div v-show="isShowAllChar || (!isShowAllChar && i < 6)" class="table-row" v-for="(char, i) in charakteristic" :key="i">
                                    <div class="table-cell table-cell--th table_label_small_padding" data-label="Название">{{ char.name }}</div>
                                    <div class="table-cell table-cell--small-size table_label_small_padding" data-label="Еденица измерения">{{ char.description }}</div>
                                </div>
                                <div @click="isShowAllChar = !isShowAllChar" v-if="!isShowAllChar && charakteristic.length > 6" class="table-row">
                                    <p class="show_char_button">Показать все характеристики</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="firstSubElementDocuments.length != 0" class="col docs_col">
                        <h3>Документы</h3>
                        <div v-for="documentElement, idx in firstSubElementDocuments" :key="idx" @click="openDocumentLink(documentElement.link)" class="document_button">
                            <div class="row document_link_row">
                                <div class="icon">
                                    <i class="far fa-list-alt"></i>
                                </div>
                                <div class="name">
                                    <p>{{ documentElement.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            v-if="product.recommended_products && product.recommended_products.length != 0"
            class="section section--no-pt section--products pb-0 mb-4 nd-popular-card"
        >
            <div class="container-l">
                <div class="inner">
                    <div class="heading mb-2">
                        <div class="heading-section">
                            <h2>Рекомендуемые товары</h2>
                        </div>
                    </div>
                </div>
                <swiper-block :elements="product.recommended_products" />
            </div>
        </div>
        <div 
            v-if="product.popular_products && product.popular_products.length != 0"
            class="section section--no-pt section--products pb-0 mb-4 nd-popular-card"
        >
            <div class="container-l">
                <div class="inner">
                    <div class="heading mb-2">
                        <div class="heading-section">
                            <h2>Популярные товары</h2>
                        </div>
                    </div>
                </div>
                <swiper-block :elements="product.popular_products" />
            </div>
        </div>
        <div 
            v-if="product.related_products && product.related_products.length != 0"
            class="section section--no-pt section--products pb-0 nd-popular-card"
        >
            <div class="container-l">
                <div class="inner">
                    <div class="heading mb-2">
                        <div class="heading-section">
                            <h2>Сопутствующие товары</h2>
                        </div>
                    </div>
                </div>
                <swiper-block :elements="product.related_products" />
            </div>
        </div>

        <div class="modal modal--gallery" :class="{'modal--opened modal--visible' : isOpenModal}" id="good_gallery">
            <div class="modal-container">
                <div class="row row--modal-gallery">
                    <div class="col">
                        <div class="modal-good-gallery" v-if="catalogImgs && catalogImgs.length != 0">
                            <div class="swiper-container slider slider--modal-good-gallery">
                                <div class="swiper-wrapper">
                                    <div 
                                        v-for="(image, i) in catalogImgs.slice(currentImgIndex, currentImgIndex+1)" 
                                        :key="i + 'D'" 
                                        class="swiper-slide"
                                    >
                                        <img
                                            :src="yandexCloud + image" 
                                            alt=""
                                            :style="(colorCardProduct ? 'background-color:' + colorCardProduct : '')"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-if="catalogImgs && catalogImgs.length > 1"
                                class="swiper-button-prev" 
                                @click="prevImg"   
                            />
                            <div 
                                v-if="catalogImgs && catalogImgs.length > 1"
                                class="swiper-button-next" 
                                @click="nextImg"  
                            />
                        </div>
                    </div>
                    <div class="col">
                        <div class="swiper-container slider slider--modal-good-gallery-thumbs swiper-vertical">
                            <div 
                                class="swiper-wrapper swiper_inmodal"
                                :style="'transform: translateY(' + scrolledPixels + 'px)'"
                                @wheel.prevent="scroll"
                            >
                                <div 
                                    v-for="(image, i) in catalogImgs" 
                                    :key="i + 'B'"
                                    class="swiper-slide"
                                    :style="i == currentImgIndex ? 'opacity: 1' : ''"
                                    @click="currentImgIndex = i"
                                >
                                    <img :src="yandexCloud + image" alt=""> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <button class="button button--default modal-close" @click="isOpenModal = false" data-modal-close>Закрыть</button>
                    </div>
                </div>
            </div>
        </div>
        <basket-modal 
            v-if="isShowModalBasket" 
        />
    </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import swiperBlock from '@/components/swiper-block/swiper-block-cardProduct'
import breadcrumbs from './breadcrumbs.vue'
import singleParameter from './single-parameter.vue'
import basketModal from '@/components/modals/basket'

export default {
    mixins: [Functions],
    components: {
        swiperBlock,
        breadcrumbs,
        singleParameter,
        basketModal,
    },
    data() {
        return {
            currentImgIndex: 0,
            scrolledPixels: 0,

            length: null,
            thickness: null,
            coating: null,
            color: null,
            colorName: null,
            currentColor: {},
            amount: 1,
            isShowModalBasket: false,
            
            thicknessList: [
                { id: 1, text: 'Hello', name: '0.45' },
                { id: 2, text: 'World', name: '0.46' }
            ],
            coatingList: [
                { id: 1, text: 'Hello', name: 'Металл' },
                { id: 2, text: 'World', name: 'Дерево' }
            ],

            blocks: [],

            isOpenModal: false,

            swiperOption: {
                loop: true,
                loopedSlides: 2,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 2,
                spaceBetween: 50,
                centeredSlides: false,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                direction: 'vertical',
                slideToClickedSlide: true
            },

            subElements: [],
            subElementsProuducts: [],
            searchFormSubElementsLists: {
                id_1c: "",
                coating: null,
                excludedIds: [],
                geometry: null,
                id_1cs: [],
                ids: [],
                insulation_thickness: null,
                internal_thickness: null,
                is_group: null,
                limit: 10,
                page: 1,
                parent_id: "",
                parent_ids: [],
                query: "",
                rolling_the_inner_lining: null,
                rolling_the_outer_cladding: null,
                sort: "10",
                thickness: null,
                сolor: null,
                root_id: null,
                diameter: null,
                head_type: null,
                shelf_a: null,
                shelf_b: null,
                tip_type: null,
            },
            changeParameter: null,
            isShowList: false,
            parentProduct: {},
            cardProductIsReady: false,
            localArrayColors: [],
            defaultImgColor: '#c7c7c5',

            isShowAllChar: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            basket: 'basket/basket',
            product: 'catalog/cardProduct',
            main: 'app/main',
            region: 'auth/city',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            regionAdresses: 'app/regionAdresses',

            order: 'basket/order',
            catalogCurrentPath: 'catalog/catalogCurrentPath',
            searchForm: 'catalog/form',
            cardColors: 'catalog/cardColors',
            castomizeCardForm: 'catalog/castomizeCardForm',
            yandexCloud: 'app/yandexCloud',
            newPosition: 'basket/newPosition',
            cardCost: 'basket/cardCost',
            charakteristicList: 'dictionary/charakteristicList',

            colorCardProduct: 'catalog/colorCardProduct',
            filters: 'catalog/filters',

            cardStructure: 'catalog/cardStructure',

            userCompanyId: 'auth/userCompanyId',
        }),
        productId() {
            return this.$route.params.id
        },
        form() {
            return Object.assign({}, this.searchForm)
        },
        editNewPosition() {
            return Object.assign({}, this.newPosition)
        },
        productDocumentArray() {
            return this.product.link_to_certificates
        },
        amountM2() {
            let amount = 0

            amount = this.editNewPosition.length * this.editNewPosition.width

            return amount
        },
        charakteristic() {
            let values = null
            let values_parsed = []
            let product

            if (this.subElementsProuducts.length != 0 && this.cardProductIsReady && this.product.is_group) {
                product = this.subElementsProuducts[0]
            }else{
                product = this.product
            }

            if (product.char_description) {
                values = product.char_description
                values_parsed = JSON.parse(values)
            }
            return values_parsed
        },
        commentProductIfGroup() {
            let response = null
            let product = null

            if (this.product.is_sub_sign) {
                product = this.product
            }else{
                product = this.parentProduct
            }

            if (product) {
                response = product.product_description
            }
            
            return response
        },
        catalogImgs() {
            let catalog = []
            if (this.product.is_sub_sign) {
                catalog = this.product.links
            }else{
                catalog = this.parentProduct.links
            }
            return catalog
        },
        objectCurrentRegion() {
            let current_region = {}
            this.regionAdresses.map((element) => {
                if (element.id_1c == this.region) {
                    current_region = element
                }
            })
            return current_region
        },
        getClassCounterWithOfAmountPos() {
            let value = ''
            if (this.amount.length > 2) {
                value = 'bigger_counter_input'
            }
            return value
        },
        firstSubElementDocuments() {
            let product = null
            if (this.subElementsProuducts.length != 0 && this.cardProductIsReady && this.product.is_group) {
                product = this.subElementsProuducts[0]
            }else{
                product = this.product
            }

            var array = []
            var responseArray = []

            if (product.link_to_certificates) {
                array = product.link_to_certificates
            }

            if (array.length != 0) {
                array.map((element) => {
                    responseArray.push(JSON.parse(element))
                })
            }
             
            return responseArray
        },
    },
    watch: {
        productId() {
            this.cleatSearchForm()
            this.blocks = []
            this.getProduct()
            this.setColorCardProduct(null) 
            this.localArrayColors = []
            this.currentColor = {}
        },
        subElementsProuducts(array) {
            if (array.length != 0) {
                this.changeNewPosition({type: 'id_1c', value: array[0].id_1c})
                this.changeNewPosition({type: 'name', value: array[0].name})
                this.changeNewPosition({type: 'price', value: array[0].price})
                this.changeNewPosition({type: 'overall_width', value: array[0].overall_width})
                this.changeNewPosition({type: 'mounting_width', value: array[0].mounting_width})
                this.changeNewPosition({type: 'base_unit_of_measure', value: array[0].base_unit_of_measure})
                this.changeNewPosition({type: 'root_id', value: this.region})
                this.changeNewPosition({type: 'company_id', value: Number(this.userCompanyId)})

                this.calculate()
            }
        },
        region() {
            // this.push('catalog')
            this.getProduct()
        },
    },
    destroyed() {
        this.clearNewPosition(this.product.id_1c)
        this.setColorCardProduct(null) 
        this.localArrayColors = []
        this.currentColor = {}
    },
    mounted() {
        this.form.root_id = this.region
        setTimeout(() => {
            this.getProduct()
        }, 1000)
        this.setColorCardProduct(null) 
    },
    methods: {
        ...Vuex.mapMutations({
            addProduct: 'basket/addProduct',
            pushToOrder: 'basket/pushToOrder',
            setForm: 'catalog/setForm',
            clearNewPosition: 'basket/clearNewPosition',
            changeNewPosition: 'basket/changeNewPosition',
            setColorCardProduct: 'catalog/setColorCardProduct',
            setArrayCardProductColors: 'catalog/setArrayCardProductColors',
            setCardProductErrorArray: 'catalog/setCardProductErrorArray',
            cleatSearchForm: 'catalog/cleatSearchForm',
        }),
        ...Vuex.mapActions({
            getCardProduct: 'catalog/getProduct',
            createPosition: 'basket/create',
            addPosition: 'basket/addPosition',
            search: 'catalog/search',
            getColors: 'catalog/getColors',
            getCardStructure: 'catalog/getCardStructure',
            calculate: 'basket/calculate',
            searchNavbar: 'catalog/searchNavbar',
            getParentForNavigationPath: 'catalog/getParentForNavigationPath',
            getFilters: 'catalog/getFilters',
            getColorsArray: 'catalog/getColorsArray',
        }),
        isHaveErrorObject(obj) {
            let is_error = false
            let length = 0
            for (let property in obj) {
                if ((typeof obj[property] === 'number')) {
                    let fixedPrice = 0
                    fixedPrice = ( (obj[property].toString().includes('.')) ? (obj[property].toString().split('.').pop().length) : (0) )
                    fixedPrice = (fixedPrice >= 2 ? obj[property].toFixed(2) : obj[property])
                    if (fixedPrice <= 0) {
                        is_error = true
                    }
                }
                length += 1
            }

            if (length > 1) {
                is_error = false
            }
            return is_error
        },
        updateLocalForm(type, val) {
            this.form[type] = val
            this.setCardProductIsReady()
        },
        setCardProductIsReady() {
            let ready = true
            let parameters = this.cardStructure
            let error_array = []
            if (parameters && parameters.length != 0) {
                parameters.map((element) => {
                    let name = element.base_name
                    let lower_name = name[0].toLowerCase() + name.substring(1)
    
                    lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    
                    lower_name = lower_name.trim()
                    if (lower_name == 'color') {
                        lower_name = 'colors'
                    }
                    if (element.value == true) {
                        if (!this.form[lower_name] && element.type == 10) {
                            ready = false
                            error_array.push(lower_name)
                        }
                        if (!this.form[lower_name] && lower_name != 'colors' && !this.isEmptyObject(this.filters[lower_name]) && !this.isHaveErrorObject(this.filters[lower_name]) && !this.isEmptyValueFilter(this.filters[lower_name])) {
                            ready = false
                            error_array.push(lower_name)
                        }
                        if (lower_name == 'colors') {
                            if (!this.form[lower_name] && this.form.coating == 'Оцинкованное' && ready == true) {
                                ready = true
                            }else if (!this.form[lower_name] && this.form.coating != 'Оцинкованное'){
                                error_array.push(lower_name)
                                ready = false
                            }
                        }
                    }
                })
            }else if (this.product.is_sub_sign == true){
                ready = true
            }
            this.setCardProductErrorArray(error_array)
            this.cardProductIsReady = ready
            
            if (this.cardProductIsReady == true) {
                this.getSubElementsLists()
            }
        },
        isEmptyValueFilter(val) {
            let resp = false
            let count = 0
            for(var value of Object.entries(val)) {
                count++
                if (Array.isArray(value)) {
                    if (value[0] == '' || value[1] == '') {
                        if (count == 1) {
                            resp = true
                        }else if (count != 1) {
                            resp = false
                        }
                    }
                }
            }
            return resp
        },
        getFixedPrice(price) {
            let fixedPrice = 0
            if (price) {
                fixedPrice = ( (price.toString().includes('.')) ? (price.toString().split('.').pop().length) : (0) )
            }
            return (fixedPrice >= 2 ? price.toFixed(2) : price)
        },
        getProduct() {
            if (!this.productId) {
                return
            }
            this.getCardProduct({ids: [Number(this.productId)], root_id: this.region, limit: 10})
                .then((response) => {
                    this.changeNewPosition({type: 'id_1c', value: response.data[0].id_1c})
                    this.changeNewPosition({type: 'price', value: response.data[0].price})
                    this.changeNewPosition({type: 'overall_width', value: response.data[0].overall_width})
                    this.changeNewPosition({type: 'length', value: null})
                    this.changeNewPosition({type: 'count_base_unit_of_measure', value: 1})
                    this.changeNewPosition({type: 'sell', value: response.data[0].sell})
                    this.changeNewPosition({type: 'amount', value: 1})
                    this.changeNewPosition({type: 'root_id', value: this.region})
                    this.amount = 1

                    if (response.data[0].html_title) {
                        document.title = response.data[0].html_title
                    }

                    if (response.data[0].html_description) {
                        let document_description = document.querySelector('head meta[name="description"]')
                        document_description.setAttribute('content', response.data[0].html_description)
                    }

                    if (response.data[0].navigation_path == null) {
                        this.getParentForNavigationPath({root_id: this.region, id_1cs: [response.data[0].parent_id]})
                    }

                    if (response.data[0].is_sub_sign) {
                        this.getCardStructure({ root_id: this.region, id_1c: response.data[0].id_1c, nameRequest: 'get card structure' })
                            .then(() => {
                                this.setBlockParameters(this.cardStructure)
                                this.getSubElements()
                                this.getSubElementsLists()
                                // this.setCardProductIsReady()
                            })
                    }else{
                        this.getParentProduct(response.data[0].parent_id)
                        
                        this.getCardStructure({ root_id: this.region, id_1c: response.data[0].id_1c, nameRequest: 'get card structure' })
                            .then(() => {
                                this.setBlockParameters(this.cardStructure)
                                this.getSubElements()
                                this.getSubElementsLists()
                                // this.setCardProductIsReady()
                            })
                        this.getColorsProduct()
                    }
                })
                .catch(() => {
                    this.push('catalog')
                })
        },
        getColorsProduct() {
            this.getColors(this.product.colors)
                .then((response) => {
                    this.localArrayColors = response.data
                })
        },
        addInBasket() {
            this.editNewPosition.amount = Number(this.amount)
            if (this.product.is_sub_sign && this.subElementsProuducts.length != 0) {
                this.editNewPosition.name = this.subElementsProuducts[0].name
                this.editNewPosition.id_1c = this.subElementsProuducts[0].id_1c
            }else{
                this.editNewPosition.name = this.product.name
                this.editNewPosition.id_1c = this.product.id_1c
            }
            this.editNewPosition.length = Number(this.editNewPosition.length)
            this.editNewPosition.width = Number(this.editNewPosition.width)
            
            if (this.order.id) {
                this.editNewPosition.order_id = this.order.id
                this.editNewPosition.company_id = Number(this.userCompanyId)

                this.addPosition(this.editNewPosition)
                    .then(() => {
                        this.amount = 1
                        this.changeShowModalBasket(true)
                        let that = this
                        setTimeout(() => {
                            that.changeShowModalBasket(false)
                        }, 1000)
                    })
            }else{
                let source_utm = JSON.parse(localStorage.getItem("utm_source"))
                this.createPosition(
                    {
                        positions: [this.editNewPosition], 
                        region_code: this.objectCurrentRegion.region_code, 
                        region_description: this.objectCurrentRegion.region_description,
                        utm: source_utm,
                        company_id: Number(this.userCompanyId),
                    })
                    .then((response) => {
                        localStorage.setItem('mayakOrderId', JSON.stringify(response.data.id))
                        this.amount = 1
                        this.changeShowModalBasket(true)
                        let that = this
                        setTimeout(() => {
                            that.changeShowModalBasket(false)
                        }, 1000)
                    })
            }            
        },
        changeAmount(type) {
            if (type == 'minus') {
                if (this.amount == 1) {
                    return
                }else if (!this.amount) {
                    this.amount = 1
                    return
                }
                this.amount--
            }
            if (type == 'plus') {
                this.amount++
            }
            if (Number(this.amount) > 9999) {
                this.amount = 9999
            }
            this.changeNewPosition({type: 'amount', value: Number(this.amount)})
            this.calculate()
        },
        pushToCatalog(id_1c) {
            this.form.parent_id = id_1c
            this.form.root_id = this.region
            this.setForm(this.form)
            this.search({from: 'catalog'})

            this.$router.push('/catalog/' + id_1c)
        },
        setBlockParameters(parameters) {
            let blocks = [
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
                {array: [], color: false},
            ]
            let last_block_id = 0
            let parameters_array = parameters

            if (Array.isArray(parameters_array)) {
                parameters_array.map((element) => {
                    if (element.type == 30 && element.value) {
                        last_block_id++
                        blocks[last_block_id].array.push(element)
                        blocks[last_block_id].color = true
                        last_block_id++
                    }else if (blocks[last_block_id].array.length == 3 && element.value) {
                        last_block_id++
                        blocks[last_block_id].array.push(element)
                    }else if (element.value){
                        blocks[last_block_id].array.push(element)
                    }
                })
            }

            this.blocks = blocks
        },
        getNameCharakteristic(str) {
            let name = null  
            
            this.charakteristicList.map((element) => {
                if (element.key == str) {
                    name = element.name
                }
            })

            return name
        },
        getSubElements(str) {
            if (!this.product.is_sub_sign) {
                return
            }
            if (str) {
                if (typeof this.newPosition[str] === 'string' && this.newPosition[str].indexOf('Выберите') == 0) {
                    this.form[str] = null
                    this.changeParameter = null
                }else{
                    if (str == 'color') {
                        this.form.colors = this.newPosition[str]
                        this.setCardProductIsReady()
                        if (this.form.colors != null) {
                            return
                        }
                    }else if (str == 'colors_in') {
                        this.form.colors_in = this.newPosition['color_inner']
                        this.setCardProductIsReady()
                        if (this.form.colors_in != null) {
                            return
                        }
                    }else if (str == 'length_of_characteristics') {
                        this.form.length_of_characteristics = this.newPosition['length']
                    }else{
                        this.form[str] = this.newPosition[str]
                    }
                    this.changeParameter = str
                }
            }else{
                this.form.limit = 10
                this.form.parent_id = this.product.id_1c
                this.form.root_id = this.region
                this.form.is_show_zero_price = false
                this.form.is_group = false
                this.form.is_deleted = null
            }
            this.getFilters(this.form)
                .then(() => {
                    this.setColorsArray('colors')
                    this.setColorsArray('colors_in')
                    this.setCardProductIsReady()
                })
        },
        setColorsArray(str) {
            let array = []
            if (!this.filters) {
                return
            }
            let local_filters = this.filters[str]
            for (let property in local_filters) {
                array.push(local_filters[property])
            }
            this.setArrayCardProductColors({colors: array, type: str})
        },
        getSubElementsLists() {
            if (!this.product.is_sub_sign) {
                return
            }
            this.searchNavbar(this.form)
                .then((resp) => {
                    this.subElementsProuducts = resp.data
                })
        },
        isIssetColor(array, value) {
            let response = false
            array.map((element) => {
                if (element == value) {
                    response = true
                }
            })
            return response
        },
        isIssetInArray(array, value) {
            let response = false
            array.map((element) => {
                if (element.name == value) {
                    response = true
                }
            })
            return response
        },
        changeShowModalBasket(val) {
            this.isShowModalBasket = val
        },
        nextImg() {
            if (this.currentImgIndex == this.catalogImgs.length-1) {
                this.currentImgIndex = 0    
                return
            }
            this.currentImgIndex += 1
        },
        prevImg() {
            if (this.currentImgIndex == 0) {
                this.currentImgIndex = this.catalogImgs.length-1   
                return
            }
            this.currentImgIndex -= 1
        },
        openModal(index) {
            this.isOpenModal = true
            this.currentImgIndex = index
        },
        scroll(element) {
            let slider = document.getElementsByClassName('swiper_inmodal')
            let slides = slider[0].querySelectorAll('.swiper-slide')
            let heightBlock = slider[0].clientHeight
            let heightSlides = 0
            if (slides.length != 0) {
                slides.forEach(function(slide) {
                    heightSlides += slide.clientHeight
                })
            }
            if (heightSlides == 0) {
                return
            }
            if (element.deltaY - Math.floor(element.deltaY) == 0 && element.deltaY > 0) {
                if (Math.abs(this.scrolledPixels-30) > Math.abs(heightBlock - heightSlides)) {
                    return
                }
                this.scrolledPixels -= 30
            }else{
                if (this.scrolledPixels == 0) {
                    return
                }
                this.scrolledPixels += 30
            }
            
        },
        setPositionColor(cardColor, type) {
            if (type == 'color') {
                this.currentColor = cardColor
                this.form.colors = cardColor.id_1c
            }
            this.setCardProductIsReady()
        },
        getParentProduct(id_1c) {
            this.searchNavbar({root_id: this.region, id_1cs: [id_1c]})
                .then((response) => {
                    this.parentProduct = response.data[0]
                })
        },
        openDocumentLink(link) {
            if (!link) {
                return
            }
            window.open(this.yandexCloud + link, '_blank')
        },
    }
}
</script>
<style lang="css">
    .table-cell--small-size{
        font-weight: 400;
        font-size: 1.6rem;
    }
    .counter input {
        /* max-width: 130px !important; */
    }
    .bigger_counter_input{
        max-width: 130px !important;
    }
</style>
<style lang="scss">
    .cardProduct_row_info{
        justify-content: space-between;

        .description_col{
            margin-right: 4rem;
            max-width: 53%;
        }

        .props_col{
            margin-right: 4rem;
            max-width: 29%;
        }

        .docs_col{
            max-width: 18%;
        }
    }

    .document_button{
        background-color: #F5F6FA;
        padding: 2rem;
        cursor: pointer;
        margin-bottom: 3rem;

        i{
            font-size: 25px;
            margin-right: 3rem;
            // margin-top: 10px;
        }

        p{
            font-weight: 600;
        }
    }

    .pb-0{
        padding-bottom: 0 !important;
    }

    .mb-2{
		margin-bottom: 2rem !important;
	}

    .mb-4{
		margin-bottom: 4rem !important;
	}

    .show_char_button{
        font-size: 1.6rem;
        font-weight: normal;
        padding: 17px 0 17px 31px;
        margin-bottom: 0;
        text-decoration: underline;
        cursor: pointer;
    }
    .table_label_small_padding{
        padding-top: 17px !important;
        padding-bottom: 17px !important;
    }

    .nd-popular-card{
        margin-top: 100px;

        .product-header{
            margin-bottom: 20px;
        }

        .product-image{
            min-height: 230px;
            height: 230px;

            img{
                max-height: 230px;
            }
        }

    }

    .document_link_row{
        flex-wrap: nowrap;
        align-items: center;

        .icon{
            width: 20%;
        }
        .name{
            width: 80%;

            p{
                text-align: center;
            }
        }
    }
</style>